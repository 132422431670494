<template>
  <div class="home">
    <div class="waviy">
      <span style="color: #09c2c1;--i:1">A</span>
      <span style="color: #7fd857;--i:2">P</span>
      <span style="color: #ff954d;--i:3">E</span>
    </div>
    <HelloWorld msg="Tombola Generator"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #151719;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, .2));
  font-size: 150px;
}

.waviy span {
  position: relative;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(.1s * var(--i));

}

@keyframes waviy {
  0%, 40%, 100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-20px)
  }
}
</style>
