<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <input type="number" v-model="nbGrille"/> Nb grille
    <input type="number" v-model="nbCases"/> Nb cases
    <button v-on:click="generate">Generate</button>
    <table style="" v-if="results.length">
      <thead>
      <tr>
        <th>Grille</th>
        <th>Case gagnante</th>
      </tr>
      </thead>
      <tr v-for="(result,index) in results" v-bind:key="index">
        <td>{{ result.grille }}</td>
        <td>{{ result.case }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data: function () {
    return {
      nbGrille: 50,
      nbCases: 15,
      results: [],
    }
  },
  methods: {
    generate: function () {
      this.results = [];
      for (let i = 0; i < this.nbGrille; i++) {
        this.results.push({
          grille: i+1,
          case: Math.round(Math.random() * this.nbCases)+1
        })
      }
    }
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table, th, td {
  border: 1px solid;
}

table {
  width: 80%;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  border-color: green;
  border-width: medium
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
